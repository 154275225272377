






























































































































































@import '~@/assets/styles/components/modal-cadastro';
.modal-cadastro-form-col-input .auto {
  background: red;
}
.theme--light.v-input input, .theme--light.v-input textarea{
  padding: 2;
}
.theme--light.v-input{
  padding: 0;
  margin: 0;
}
#bug{
  margin-top: 7px !important
}
